import React, { useState, useRef, useEffect } from 'react';
import {
  CheckCircle2Icon,
  XIcon,
  InfoIcon,
  ArrowRightIcon,
  LightbulbIcon,
  StarIcon,
  ShieldIcon,
  HeartIcon,
  CodeIcon,
  PaletteIcon,
  RocketIcon,
  ServerIcon,
  MessageCircleIcon,
  PhoneIcon,
  ArrowLeftIcon,
  ChevronUpIcon,
  DatabaseIcon,
  CloudIcon,
  BrainCircuitIcon,
  LayoutIcon,
} from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useForm, ValidationError } from '@formspree/react';

const EnhancedPricing = () => {
  const [billingCycle, setBillingCycle] = useState('annual');
  const [showFeatureTooltip, setShowFeatureTooltip] = useState(null);

  const pricingPlans = [
    {
      name: 'Starter',
      price: {
        annual: 150,
        lifetime: 200,
      },
      description: 'Perfect for small businesses and startups',
      features: [
        {
          name: 'Responsive Design',
          included: true,
          tooltip: 'Fully responsive on all devices and screen sizes',
        },
        {
          name: '5 Pages',
          included: true,
          tooltip: 'Home, About, Services, Contact, and one custom page',
        },
        {
          name: 'Contact Form',
          included: true,
          tooltip: 'Professional contact form with spam protection',
        },
        {
          name: 'Basic SEO',
          included: true,
          tooltip: 'Essential SEO setup for better visibility',
        },
        {
          name: 'Mobile Optimization',
          included: true,
          tooltip: 'Optimized for mobile devices',
        },
        {
          name: 'Custom Animations',
          included: true,
          tooltip: 'Advanced animations and interactions',
        },
        {
          name: 'E-commerce Features',
          included: false,
          tooltip: 'Online store functionality',
        },
      ],
      highlightFeature: '5-day delivery',
      ctaText: 'Start Small',
      icon: <LightbulbIcon className="w-6 h-6" />,
    },
    {
      name: 'Professional',
      price: {
        annual: 300,
        lifetime: 400,
      },
      description: 'Ideal for growing businesses',
      features: [
        {
          name: 'Responsive Design',
          included: true,
          tooltip: 'Fully responsive on all devices and screen sizes',
        },
        {
          name: '10 Pages',
          included: true,
          tooltip: 'Double the pages of Starter plan',
        },
        {
          name: 'Contact Form',
          included: true,
          tooltip: 'Advanced form with custom fields and integrations',
        },
        {
          name: 'Robust SEO',
          included: true,
          tooltip: 'Improved SEO strategy with more advanced tactics',
        },
        {
          name: 'Enhanced Mobile Optimization',
          included: true,
          tooltip: 'Enhanced mobile experience',
        },
        {
          name: 'Robust Custom Animations',
          included: true,
          tooltip: 'Smooth, engaging animations throughout',
        },
        {
          name: 'E-commerce Features',
          included: true,
          tooltip: 'Online store functionality',
        },
      ],
      highlightFeature: '10-day delivery',
      ctaText: 'Go Professional',
      icon: <StarIcon className="w-6 h-6" />,
      popular: true,
    },
    {
      name: 'Enterprise',
      price: {
        annual: 600,
        lifetime: 800,
      },
      description: 'For large-scale projects and businesses',
      features: [
        {
          name: 'Responsive Design',
          included: true,
          tooltip: 'Fully responsive with custom breakpoints',
        },
        {
          name: 'Unlimited Pages',
          included: true,
          tooltip: 'As many pages as you need',
        },
        {
          name: 'Contact Form',
          included: true,
          tooltip: 'Enterprise-grade forms with full customization',
        },
        {
          name: 'Advanced SEO',
          included: true,
          tooltip: 'Premium SEO package with monthly updates',
        },
        {
          name: 'Premium Mobile Optimization',
          included: true,
          tooltip: 'Premium mobile experience with PWA features',
        },
        {
          name: 'Advanced Custom Animations',
          included: true,
          tooltip: 'Premium animations and micro-interactions',
        },
        {
          name: 'Advanced E-commerce Features',
          included: true,
          tooltip: 'Full e-commerce functionality',
        },
      ],
      highlightFeature: '15-day delivery',
      ctaText: 'Go Enterprise',
      icon: <ShieldIcon className="w-6 h-6" />,
    },
  ];

  const handlePlanClick = (planName) => {
    if (planName === 'Starter' && billingCycle === 'annual') {
      window.location.href = 'https://buy.stripe.com/test_5kA4hL0IkdEkg00eUX';
    } else if (planName === 'Starter' && billingCycle === 'lifetime') {
      window.location.href = 'https://buy.stripe.com/test_7sI6pT0Ik2ZG5lm9AA';
    } else if (planName === 'Professional' && billingCycle === 'annual') {
      window.location.href = 'https://buy.stripe.com/test_bIYbKd8aMbwc29acMQ';
    } else if (planName === 'Professional' && billingCycle === 'lifetime') {
      window.location.href = 'https://buy.stripe.com/test_14kdSleza2ZG8xy8wx';
    } else if (planName === 'Enterprise' && billingCycle === 'annual') {
      window.location.href = 'https://buy.stripe.com/test_aEU5lPaiU1VCbJK6ot';
    } else if (planName === 'Enterprise' && billingCycle === 'lifetime') {
      window.location.href = 'https://buy.stripe.com/test_8wM01v9eQ1VC4hi6oq';
    }
  };

  return (
<section className="py-20 px-6 bg-gray-900">
  <div className="max-w-6xl mx-auto">
    <div className="text-center mb-12">
      <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
        Simple, Transparent Pricing
      </h2>
      <div className="text-gray-300 max-w-2xl mx-auto mb-8">
        <p>Choose the perfect plan for your business.</p>
        <p>All plans include our award-winning support and satisfaction guarantee.</p>
        
        {/* Add a styled horizontal rule */}
        <hr className="my-4 border-t border-indigo-500 w-1/2 mx-auto" />

        {/* Email section with additional top margin */}
        <p>
          After purchase, email: <span className="text-indigo-400 font-semibold"><a href="mailto:inquiries@mdwebworks.tech" className="hover:underline">inquiries@mdwebworks.tech</a></span> with your site details.
        </p>
        <p>We’ll take it from there and bring your vision to life!</p>
      </div>

      <div className="inline-flex items-center bg-gray-800 p-1 rounded-lg">
        <button
          onClick={() => setBillingCycle('annual')}
          className={`px-4 py-2 rounded-md transition-all ${
            billingCycle === 'annual'
              ? 'bg-indigo-600 text-white'
              : 'text-gray-400 hover:text-gray-100'
          }`}
        >
          Annual
        </button>
        <button
          onClick={() => setBillingCycle('lifetime')}
          className={`px-4 py-2 rounded-md transition-all ${
            billingCycle === 'lifetime'
              ? 'bg-indigo-600 text-white'
              : 'text-gray-400 hover:text-gray-100'
          }`}
        >
          Lifetime
          <span className="ml-1 text-xs text-emerald-400"> Save £</span>
        </button>
      </div>
    </div>



        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {pricingPlans.map((plan) => (
            <div
              key={plan.name}
              className={`relative ${
                plan.popular
                  ? 'bg-gradient-to-b from-indigo-900 to-gray-800 border-indigo-500 transform scale-105'
                  : 'bg-gray-800 border-gray-700 hover:border-indigo-500'
              } rounded-xl p-8 border-2 transition-all duration-300`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-indigo-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                  Most Popular
                </div>
              )}

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-indigo-600/10 rounded-lg text-indigo-400">
                    {plan.icon}
                  </div>
                  <h3 className="text-2xl font-bold text-gray-100">{plan.name}</h3>
                </div>
              </div>

              <p className="text-gray-400 mb-6">{plan.description}</p>

              <div className="mb-6">
                <div className="flex items-baseline">
                  <span className="text-4xl font-bold text-indigo-400">
                    £{billingCycle === 'annual' ? plan.price.annual : plan.price.lifetime}
                  </span>
                  <span className="text-gray-400 ml-2">/project</span>
                </div>
                {billingCycle === 'lifetime' && (
                  <p className="text-emerald-400 text-sm mt-2">
                    Save Big with a Lifetime Plan!
                  </p>
                )}
              </div>

              <div className="space-y-4 mb-8">
                {plan.features.map((feature, index) => (
                  <div
                    key={feature.name}
                    className="flex items-center justify-between"
                    onMouseEnter={() => setShowFeatureTooltip(plan.name + index)}
                    onMouseLeave={() => setShowFeatureTooltip(null)}
                  >
                    <div className="flex items-center text-gray-300">
                      {feature.included ? (
                        <CheckCircle2Icon className="w-5 h-5 text-emerald-400 mr-2" />
                      ) : (
                        <XIcon className="w-5 h-5 text-gray-500 mr-2" />
                      )}
                      {feature.name}
                    </div>
                    <button className="text-gray-400 hover:text-gray-300">
                      <InfoIcon className="w-4 h-4" />
                    </button>
                    {showFeatureTooltip === plan.name + index && (
                      <div className="absolute z-10 bg-gray-700 text-gray-100 p-2 rounded-md text-sm max-w-xs ml-4">
                        {feature.tooltip}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="text-center mb-8">
                <div className="flex items-center justify-center text-gray-300 text-sm">
                  <HeartIcon className="w-4 h-4 text-indigo-400 mr-2" />
                  {plan.highlightFeature}
                </div>
              </div>

              <button
                onClick={() => handlePlanClick(plan.name)}
                className={`w-full flex items-center justify-center py-3 rounded-lg font-semibold transition-colors ${
                  plan.popular
                    ? 'bg-indigo-600 hover:bg-indigo-700 text-white'
                    : 'bg-gray-700 hover:bg-gray-600 text-gray-100'
                }`}
              >
                {plan.ctaText}
                <ArrowRightIcon className="w-4 h-4 ml-2" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ParticleBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 1.5 + 1;
        this.speedX = (Math.random() - 0.5) * 0.5;
        this.speedY = (Math.random() - 0.5) * 0.5;
        this.color = `rgba(59, 130, 246, ${Math.random() * 0.5 + 0.2})`;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.x > canvas.width || this.x < 0) this.speedX *= -1;
        if (this.y > canvas.height || this.y < 0) this.speedY *= -1;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fillStyle = this.color;
        ctx.fill();
      }
    }

    const initParticles = () => {
      particles = [];
      const numberOfParticles = Math.floor((canvas.width * canvas.height) / 20000);
      for (let i = 0; i < numberOfParticles; i++) {
        particles.push(new Particle());
      }
    };

    const connectParticles = () => {
      const maxDistance = 150;
      for (let i = 0; i < particles.length; i++) {
        for (let j = i; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < maxDistance) {
            const opacity = 1 - distance / maxDistance;
            ctx.beginPath();
            ctx.strokeStyle = `rgba(59, 130, 246, ${opacity * 0.2})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    };

    const animateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      connectParticles();
      animationFrameId = requestAnimationFrame(animateParticles);
    };

    const handleResize = () => {
      setCanvasSize();
      initParticles();
    };

    setCanvasSize();
    initParticles();
    animateParticles();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none opacity-30"
      style={{ zIndex: 0 }}
    />
  );
};

const WebDesignLandingPage = () => {
  const [activeTab, setActiveTab] = useState('design');
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [animatedStats, setAnimatedStats] = useState({
    projects: 0,
    clients: 0,
    awards: 0,
    experience: 0,
  });
  const contactRef = useRef(null);
  const solutionsRef = useRef(null);
  const statsRef = useRef(null);
  const pricingRef = useRef(null);

  const growthData = [
    { month: 'Month 1', beforeWebsite: 100, afterWebsite: 100 },
    { month: 'Month 2', beforeWebsite: 120, afterWebsite: 350 },
    { month: 'Month 3', beforeWebsite: 135, afterWebsite: 800 },
    { month: 'Month 4', beforeWebsite: 142, afterWebsite: 1500 },
    { month: 'Month 5', beforeWebsite: 150, afterWebsite: 2400 },
    { month: 'Month 6', beforeWebsite: 165, afterWebsite: 3500 },
  ];

  const techStack = [
    { name: 'React', icon: <CodeIcon className="w-6 h-6" />, color: 'text-blue-400' },
    { name: 'Node.js', icon: <ServerIcon className="w-6 h-6" />, color: 'text-green-400' },
    { name: 'Cloud', icon: <CloudIcon className="w-6 h-6" />, color: 'text-purple-400' },
    { name: 'Database', icon: <DatabaseIcon className="w-6 h-6" />, color: 'text-yellow-400' },
    { name: 'AI/ML', icon: <BrainCircuitIcon className="w-6 h-6" />, color: 'text-pink-400' },
    { name: 'UI/UX', icon: <LayoutIcon className="w-6 h-6" />, color: 'text-indigo-400' },
  ];

  const serviceDetails = {
    design: {
      icon: <PaletteIcon className="w-12 h-12 text-indigo-400" />,
      title: 'Stunning Visual Design',
      description:
        'We craft pixel-perfect, aesthetically pleasing websites that captivate your audience from the first glance. Our design philosophy blends cutting-edge trends with timeless elegance.',
    },
    performance: {
      icon: <RocketIcon className="w-12 h-12 text-emerald-400" />,
      title: 'Blazing Fast Performance',
      description:
        'Lightning-quick loading times and optimized user experience. We ensure your website not only looks beautiful but performs exceptionally across all devices and platforms.',
    },
    development: {
      icon: <CodeIcon className="w-12 h-12 text-purple-400" />,
      title: 'Advanced Web Development',
      description:
        'Robust, scalable, and secure web solutions using the latest technologies. We transform your vision into a powerful digital presence that drives results.',
    },
  };

  const testimonials = [
    {
      name: 'Sarah Johnson',
      role: 'CEO, TechInnovate',
      quote: 'Their web design completely transformed our online presence. Absolutely phenomenal work!',
      rating: 5,
    },
    {
      name: 'Michael Chen',
      role: 'Founder, StartupHub',
      quote: 'Professional, creative, and delivered beyond our expectations. Highly recommended!',
      rating: 5,
    },
    {
      name: 'Emily Davis',
      role: 'Marketing Director, Brandify',
      quote: 'A true partner in our growth journey. Their team helped us create a seamless online experience.',
      rating: 4,
    },
    {
      name: 'John Doe',
      role: 'Owner, The Artisan Group',
      quote: 'They took our vision and brought it to life. Excellent service and support throughout.',
      rating: 5,
    },
    {
      name: 'Anna Smith',
      role: 'Product Manager, ShopSphere',
      quote: 'From concept to launch, they made the process easy and enjoyable. The result? Stunning!',
      rating: 5,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          animateStats();
        }
      },
      { threshold: 0.5 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const animateStats = () => {
    const duration = 2000;
    const steps = 50;
    const finalStats = { projects: 250, clients: 120, awards: 15, experience: 8 };

    let step = 0;
    const interval = setInterval(() => {
      if (step >= steps) {
        clearInterval(interval);
        setAnimatedStats(finalStats);
        return;
      }

      setAnimatedStats((prev) => ({
        projects: Math.ceil((finalStats.projects / steps) * step),
        clients: Math.ceil((finalStats.clients / steps) * step),
        awards: Math.ceil((finalStats.awards / steps) * step),
        experience: Math.ceil((finalStats.experience / steps) * step),
      }));

      step++;
    }, duration / steps);
  };

  const handleNextTestimonial = () => {
    setTestimonialIndex((testimonialIndex + 1) % testimonials.length);
  };

  const handlePrevTestimonial = () => {
    setTestimonialIndex((testimonialIndex - 1 + testimonials.length) % testimonials.length);
  };

  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToSolutions = () => {
    solutionsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToPricing = () => {
    pricingRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="bg-gray-900 min-h-screen text-gray-100 relative">
      <ParticleBackground />

      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 bg-indigo-600 p-4 rounded-full shadow-lg transition-all duration-300 hover:bg-indigo-700 z-50 ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
        aria-label="Scroll to top"
      >
        <ChevronUpIcon className="w-6 h-6" />
      </button>

      <header className="bg-gradient-to-br from-gray-900 via-indigo-950 to-gray-900 min-h-[75vh] px-6 border-b border-gray-800 flex items-center justify-center">
        <div className="max-w-6xl mx-auto text-center">
          <div className="max-w-4xl mx-auto">
            <div className="mb-8">
              <h1 className="text-5xl md:text-7xl font-bold mb-4 leading-tight">
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 animate-gradient">
                  Elevate Your Digital Presence
                </span>
              </h1>
              <div className="h-1 w-24 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full mb-6 mx-auto"></div>
              <p className="text-xl md:text-2xl text-gray-300 mb-12 leading-relaxed max-w-2xl mx-auto">
                Professional web design that transforms your business and leaves a lasting impression. We don't just build websites; we create digital experiences.
              </p>
            </div>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 justify-center">
              <button
                onClick={scrollToPricing}
                className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-4 rounded-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 font-semibold text-lg shadow-lg hover:shadow-indigo-500/25"
              >
                Get Started
              </button>
              <button
                onClick={scrollToSolutions}
                className="border-2 border-indigo-500 text-indigo-400 px-8 py-4 rounded-lg hover:bg-indigo-950 transition-colors font-semibold text-lg hover:border-purple-500 hover:text-purple-400"
              >
                View Portfolio
              </button>
            </div>
          </div>
        </div>
      </header>

      <section ref={solutionsRef} className="py-20 px-6 bg-gray-900">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
            Our Comprehensive Web Solutions
          </h2>
          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
            {Object.keys(serviceDetails).map((service) => (
              <div
                key={service}
                className={`flex-1 p-8 rounded-xl transition-all duration-300 cursor-pointer ${
                  activeTab === service
                    ? 'bg-gray-800 shadow-2xl scale-105 border-2 border-indigo-500'
                    : 'bg-gray-800/50 hover:bg-gray-800/80 hover:shadow-xl border border-gray-700/50 hover:border-indigo-500/50'
                }`}
                onClick={() => setActiveTab(service)}
              >
                {serviceDetails[service].icon}
                <h3 className="text-2xl font-semibold mt-4 mb-2 text-gray-100">
                  {serviceDetails[service].title}
                </h3>
                <p className="text-gray-400">{serviceDetails[service].description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 px-6 bg-gray-850">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
            What Our Clients Say
          </h2>
          <div className="relative flex flex-col items-center justify-center md:flex-row md:space-x-8">
            <button
              onClick={handlePrevTestimonial}
              className="md:absolute md:left-0"
              aria-label="Previous testimonial"
            >
              <ArrowLeftIcon className="w-8 h-8 text-gray-400 hover:text-indigo-400" />
            </button>
            <div className="bg-gray-800 p-8 rounded-xl shadow-xl border border-gray-700 max-w-sm mx-auto text-left mt-4 md:mt-0">
              <div className="flex items-center mb-4">
                <div className="flex text-yellow-500 mr-4">
                  {[...Array(testimonials[testimonialIndex].rating)].map((_, i) => (
                    <StarIcon key={i} className="w-6 h-6 fill-current" />
                  ))}
                </div>
                <MessageCircleIcon className="w-8 h-8 text-indigo-400" />
              </div>
              <p className="italic text-gray-300 mb-4">
                "{testimonials[testimonialIndex].quote}"
              </p>
              <div className="font-semibold text-gray-100">
                {testimonials[testimonialIndex].name}
              </div>
              <div className="text-indigo-400 text-sm">{testimonials[testimonialIndex].role}</div>
            </div>
            <button
              onClick={handleNextTestimonial}
              className="md:absolute md:right-0"
              aria-label="Next testimonial"
            >
              <ArrowRightIcon className="w-8 h-8 text-gray-400 hover:text-indigo-400" />
            </button>
          </div>
        </div>
      </section>

      <section ref={statsRef} className="py-20 px-6 bg-gray-850">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: 'Projects Completed', value: animatedStats.projects },
              { label: 'Happy Clients', value: animatedStats.clients },
              { label: 'Awards Won', value: animatedStats.awards },
              { label: 'Years Experience', value: animatedStats.experience },
            ].map((stat, index) => (
              <div
                key={index}
                className="text-center p-6 bg-gray-800 rounded-xl border border-gray-700 hover:border-indigo-500 transition-all duration-300"
              >
                <div className="text-4xl font-bold text-indigo-400 mb-2">{stat.value}+</div>
                <div className="text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 px-6 bg-gray-900">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
            Explosive Customer Growth
          </h2>
          <p className="text-gray-300 text-center mb-16 max-w-2xl mx-auto">
            Watch your customer base skyrocket with our professional web design.
          </p>
          <div className="bg-gray-800 p-8 rounded-xl border border-gray-700">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={growthData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="month" stroke="#9CA3AF" fontSize={11} tick={{ fontSize: 11 }} />
                <YAxis
                  stroke="#9CA3AF"
                  fontSize={11}
                  label={{
                    value: 'Avg Customer Growth %',
                    angle: -90,
                    position: 'insideLeft',
                    style: { fill: '#9CA3AF' },
                  }}
                  tick={{ fontSize: 11 }}
                  domain={[0, 4000]}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: '1px solid #374151',
                    borderRadius: '0.5rem',
                    color: '#E5E7EB',
                    fontSize: '12px',
                  }}
                />
                <Legend
                  wrapperStyle={{
                    fontSize: '12px',
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="beforeWebsite"
                  name="Traditional Marketing"
                  stroke="#818CF8"
                  strokeWidth={2}
                  dot={{ fill: '#818CF8', r: 6 }}
                />
                <Line
                  type="monotone"
                  dataKey="afterWebsite"
                  name="With Professional Website"
                  stroke="#34D399"
                  strokeWidth={4}
                  dot={{ fill: '#34D399', r: 6 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12">
            <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
              <h3 className="text-3xl font-bold text-purple-400 mb-2">3500% Growth</h3>
              <p className="text-gray-300 text-lg">
                Potential customer base increase over 6 months with our professional web design.
              </p>
            </div>
            <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
              <h3 className="text-3xl font-bold text-purple-400 mb-2">20x Faster</h3>
              <p className="text-gray-300 text-lg">
                Growth rate compared to traditional marketing methods.
              </p>
            </div>
            <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
              <h3 className="text-3xl font-bold text-purple-400 mb-2">Unlimited</h3>
              <p className="text-gray-300 text-lg">
                Potential reach with our optimized web presence.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 px-6 bg-gray-850">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
            Our Tech Stack
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {techStack.map((tech, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row items-center sm:justify-start text-center sm:text-left p-6 bg-gray-800 rounded-xl border border-gray-700 hover:border-indigo-500 transition-all duration-300 group"
              >
                <div
                  className={`${tech.color} mb-4 sm:mb-0 sm:mr-4 group-hover:scale-110 transition-transform duration-300`}
                >
                  {tech.icon}
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-100">{tech.name}</h3>
                  <p className="text-gray-400">Advanced Implementation</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section ref={pricingRef}>
        <EnhancedPricing />
      </section>

      <section
        ref={contactRef}
        className="py-20 px-6 bg-gradient-to-br from-gray-900 via-indigo-950 to-gray-900"
      >
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-12 mb-8 md:mb-0">
            <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
              Let's Create Something Amazing
            </h2>
            <p className="text-xl mb-8 text-gray-300">
              Have a project in mind? We'd love to hear about it. Fill out the form or contact us
              directly for a free consultation.
            </p>
            <div className="flex items-center space-x-4 mb-4">
              <PhoneIcon className="w-8 h-8 text-emerald-400" />
              <span className="text-lg text-gray-300">(+351) 967-621-855</span>
            </div>
            <div className="flex items-center space-x-4">
              <MessageCircleIcon className="w-8 h-8 text-emerald-400" />
              <span className="text-lg text-gray-300">inquiries@mdwebworks.tech</span>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <ContactForm />
          </div>
        </div>
      </section>
    </div>
  );
};

const ContactForm = () => {
  const [state, handleSubmit] = useForm('xyzyedop');
  if (state.succeeded) {
    return <p className="text-green-400 text-center">Thank you! Your message has been sent.</p>;
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-gray-800 p-8 rounded-xl shadow-2xl border border-gray-700"
    >
      <h3 className="text-2xl font-semibold mb-6 text-center text-gray-100">
        Request a Free Consultation
      </h3>
      <div className="mb-4">
        <label htmlFor="name" className="block mb-2 text-gray-300">
          Full Name
        </label>
        <input
          id="name"
          type="text"
          name="name"
          required
          className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-100"
          placeholder="Your Name"
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block mb-2 text-gray-300">
          Email Address
        </label>
        <input
          id="email"
          type="email"
          name="email"
          required
          className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-100"
          placeholder="you@example.com"
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <div className="mb-6">
        <label htmlFor="message" className="block mb-2 text-gray-300">
          Project Details
        </label>
        <textarea
          id="message"
          name="message"
          required
          className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-100"
          rows={4}
          placeholder="Tell us about your project..."
        />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition-colors font-semibold"
      >
        Send Project Inquiry
      </button>
    </form>
  );
};

const App = () => {
  return (
    <>
      <WebDesignLandingPage />
    </>
  );
};

export default App;